import '../helpers/helpers';

import * as slickSliders from '../functions/slickSliders';
import * as scrollEvent from '../eventHandlers/scrollEvent';

import * as Lazy from 'jquery-lazy';

import AOS from 'aos';

document.addEventListener('DOMContentLoaded', function(){

	function toggleOverlay() {
		var overlay = $('.overlay');

		if(overlay.hasClass('open')) {
			overlay.removeClass('open');
			overlay.addClass('close');

			setTimeout(function(){
				overlay.removeClass('close');
			}, 250);
		} else if(!overlay.hasClass('close')) {
			overlay.addClass('open');
		}
	}

	scrollEvent.init();

	var $lightgallery = $('.lightgallery'),
		$mainSlider = document.querySelectorAll('.main-slider'),
		$slider = document.querySelectorAll('.slider'),
		$highlights = document.querySelectorAll('.highlights'),
		$gallerySlider = document.querySelectorAll('.gallery-slider')

	if($mainSlider || $slider || $highlights || $gallerySlider){
		slickSliders.init();
	}

	$('.trigger-btn, .overlay-close').on('click', toggleOverlay);

	$('#overlay-menu').find('.menu-item-has-children a').append('<span class="caret"></span>');

	$(document).find('#overlay-menu').find('.menu-item-has-children .caret').on('click', function(e){
		e.preventDefault();
		e.stopPropagation();

		$(this).parent().parent().toggleClass('active');

		$(this).parent().parent().find('.sub-menu').first().slideToggle();
	});

	if($(window).width() <= 500) {
		$('p').filter(function() {
	        return $.trim($(this).text()) === '' && $(this).children().length == 0;
	    }).remove();
	}

	if($lightgallery.length) {
		$(".lightgallery").lightGallery({
			selector: 'this' 
		});

		// Thumbnails
		// $(".small-gallery").lightGallery({
		// 	thumbnail:true
		// });
	}

	function fixBtnPosition() {
	      let imageWidth = $( ".highlights .slider-item .slider-img" ).width()-191;
	      let navBtnPosition = $( ".slider-holder .arrows-hld2" ).css("left", imageWidth + "px");
	}
	fixBtnPosition()
	  
	$( window ).resize(function() {
	   	fixBtnPosition()
	});

	$('.quantity-button').off('click').on('click', function () {
		if ($(this).hasClass('plus-button')) {
	    	var addValue = parseInt($(this).parent().find('input').val()) + 1;
			$(this).parent().find('input').val(addValue).trigger('change');

			if($(this).parent().hasClass('rooms')){
				$('#booking-form .qRms').attr({'value': addValue});
			} else if($(this).parent().hasClass('adults')){
				console.log('bb');
				$('#booking-form .qAdlt').attr({'value': addValue});
			} else if($(this).parent().hasClass('children')){
				$('#booking-form .qChld').attr({'value': addValue});
			}
		}

		if ($(this).hasClass('minus-button')) {
	    	var removeValue = parseInt($(this).parent().find('input').val()) - 1;
			if( removeValue <= 0 ) {
	      		removeValue = 0;
			}
			$(this).parent().find('input').val(removeValue).trigger('change');

			if($(this).parent().hasClass('rooms')){
				$('#booking-form .qRms').attr({'value': removeValue});
			} else if($(this).parent().hasClass('adults')){
				$('#booking-form .qAdlt').attr({'value': removeValue});
			} else if($(this).parent().hasClass('children')){
				$('#booking-form .qChld').attr({'value': removeValue});
			}
		}
	});

	$('.button--book-now').on('click', function(e){
		e.stopPropagation();
		e.preventDefault();

		$('.booking-form').slideToggle();
	});

	$('.booking-close').on('click', function(e){
		e.stopPropagation();
		e.preventDefault();

		$('.booking-form').slideUp();
	});

	$('.btn--contact').on('click', function(){
		$('.popup-hld--contact').addClass('js-show');
	});

	$('.close-btn, .popup-blank').on('click', function(){
		$('.popup-hld').removeClass('js-show');
	});

});

AOS.init({
	duration: 1000,
	delay: 250,
	easing: 'ease-out',
	once: true,
	disable: function() {
    	var maxWidth = 768;
        return window.innerWidth < maxWidth;
    }
});

// $(window).on('load', function() {
	
// 	if(!Modernizr.csspointerevents && !Modernizr.touchevents) {
// 		var mySkrollr = skrollr.init({
// 			edgeStrategy: 'set',
// 			smoothScrolling: false,
// 			forceHeight: false
// 		});
// 	}

// });